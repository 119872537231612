import { useQuery } from '@tanstack/react-query';
import { getPermission } from 'services/middleware/auth';

export function usePermissions(user) {
  const enabled = !!user;

  const { data: isTalker } = useQuery({
    queryKey: ['isTalker'],
    queryFn: () => getPermission('talker'),
    enabled,
    initialData: false,
    refetchOnWindowFocus: false,
  });

  const { data: isMeetingManager } = useQuery({
    queryKey: ['isMeetingManager'],
    queryFn: () => getPermission('meetingManager'),
    enabled,
    initialData: false,
    refetchOnWindowFocus: false,
  });

  const { data: isLicenseManager } = useQuery({
    queryKey: ['licenseManager'],
    queryFn: () => getPermission('licenses'),
    enabled,
    initialData: false,
    refetchOnWindowFocus: false,
  });

  const { data: isGroupManager } = useQuery({
    queryKey: ['groupManager'],
    queryFn: () => getPermission('groupManager'),
    enabled,
    initialData: false,
    refetchOnWindowFocus: false,
  });

  const { data: isStudentManager } = useQuery({
    queryKey: ['studentManager'],
    queryFn: () => getPermission('studentEngagementView'),
    enabled,
    initialData: false,
    refetchOnWindowFocus: false,
  });

  const { data: isTeacher } = useQuery({
    queryKey: ['isTeacher'],
    queryFn: () => getPermission('teacher'),
    enabled,
    initialData: false,
    refetchOnWindowFocus: false,
  });

  const { data: isKids } = useQuery({
    queryKey: ['eduBasicKid'],
    queryFn: () => getPermission('kids'),
    enabled,
    initialData: false,
    refetchOnWindowFocus: false,
  });

  const { data: isCourseManager } = useQuery({
    queryKey: ['courseManager'],
    queryFn: () => getPermission('courseManager'),
    enabled,
    initialData: false,
    refetchOnWindowFocus: false,
  });

  return {
    isTalker,
    isMeetingManager,
    isLicenseManager,
    isGroupManager,
    isStudentManager,
    isTeacher,
    isKids,
    isCourseManager,
  };
}
