import { AppBar, Box, Toolbar } from '@mui/material';

import Logo from 'components/Logo';

export default function EmptyHeader() {
  return (
    <AppBar
      id="header"
      position="sticky"
      color="inherit"
      elevation={1}
      sx={{ pb: 0.5 }}
    >
      <Toolbar>
        <Box
          alignItems="center"
          display="flex"
          flexGrow={1}
          justifyContent="space-between"
        >
          <Logo link="#" />
        </Box>
      </Toolbar>
    </AppBar>
  );
}
