import Dynamic from 'next/dynamic';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

import Header from 'components/Shared/Header';
import EmptyHeader from 'components/Shared/EmptyHeader';

import { DefaultMenuNav } from 'mocks/menuNav';
import { useRouter } from 'next/router';

const Footer = Dynamic(() => import('components/Shared/Footer'), {
  ssr: true,
});

Layout.propTypes = {
  maxWidth: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  footerProps: PropTypes.instanceOf(Object),
  tabs: PropTypes.instanceOf(Array),
};

Layout.defaultProps = {
  footerProps: {},
  tabs: DefaultMenuNav,
};

export default function Layout({ children, maxWidth, footerProps, tabs }) {
  const router = useRouter();
  const isSantanderRoute = router.asPath.toLowerCase().includes('santander');

  const ChosenHeader = isSantanderRoute ? EmptyHeader : Header;

  return (
    <Box bgcolor="background.paper">
      <ChosenHeader
        menuNavHeader={DefaultMenuNav}
        menuNavSideMenu={DefaultMenuNav}
        tabs={tabs}
      />

      {children}

      <Footer maxWidth={maxWidth} {...footerProps} />
    </Box>
  );
}
